




































































  import { Vue, Component, Prop } from 'nuxt-property-decorator'
  import { CMS, Categories } from '@one/types'
  import { namespace } from 'vuex-class'

  const categories = namespace('categories')

  @Component({
    components: {
      OneCategoryBox: () => import('~/components/molecules/boxes/OneCategoryBox.vue'),
      OneCategoryItem: () => import('~/components/molecules/items/OneCategoryItem.vue'),
    },
  })
  export default class OneCmsCategoryOverview extends Vue {
    @Prop({
      type: String,
      required: true,
    })
    readonly categoryId!: string

    @Prop({
      type: String,
      required: true,
    })
    readonly img!: string

    @Prop({
      type: Array,
      required: true,
    })
    readonly highlightedCategories!: Array<CMS.Categories.HighlightedCategory>

    @Prop({
      type: Array,
      required: true,
    })
    readonly manufacturers!: Array<CMS.Categories.Manufacturer>

    @categories.State categories: any
    @categories.Getter getCategory: any
    @categories.Action selectCategory: any

    get category(): Categories.Category {
      return this.getCategory(this.categoryId)
    }

    get subcategories(): Array<Categories.Category> {
      return this.category.children.map((categoryId: number) => this.getCategory(categoryId))
    }

    get quantityToDisplay() {
      return ['md', 'lg', 'xl'].find(x => x === this.$mq) ? 6 : 4
    }

    onCategoryClick(categoryId: string) {
      this.selectCategory(categoryId)
      const categoryPath: string | null = this.$routes.getCategoryPath(categoryId)
      if (categoryPath) {
        this.$router.push(categoryPath)
      }
    }

    onCategoryTitleClick() {
      this.onCategoryClick(this.category.id)
    }

    isCategoryExist(subcategoryId: string): boolean {
      return subcategoryId in this.categories
    }
  }
